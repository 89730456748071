export type LiquityFrontendConfig = {
  frontendTag: string;
  infuraApiKey?: string;
  testnetOnly?: boolean;
};

export const getConfig = (): Promise<LiquityFrontendConfig> =>
  Promise.resolve({
    frontendTag: process.env.REACT_APP_FRONTEND_TAG!,
    infuraApiKey: process.env.REACT_APP_INFURA_API_KEY,
  });
